/**
 * The Responsive Bootstrap Toolkit.
 *
 * @link https://github.com/maciej-gurban/responsive-bootstrap-toolkit
 *
 * Usage:
 * if(viewport.is('>=sm')) { ... }
 */

import ResponsiveBootstrapToolkit from 'responsive-toolkit';

(function ($, viewport) {
  // Bootstrap 4 visibility classes.
  const visibilityDivs = {
    xs: $('<div class="device-xs d-block d-sm-none"></div>'),
    sm: $('<div class="device-sm d-none d-sm-block d-md-none"></div>'),
    md: $('<div class="device-md d-none d-md-block d-lg-none"></div>'),
    lg: $('<div class="device-lg d-none d-lg-block d-xl-none"></div>'),
    xl: $('<div class="device-xl d-none d-xl-block"></div>'),
    xxl: $('<div class="device-xxl d-none d-xxl-block"></div>'),
  };

  viewport.use('bootstrap4', visibilityDivs);
  window.viewport = viewport;
})($, ResponsiveBootstrapToolkit);
