/**
 * CSS Variables.
 *
 * They are used with other JS and SCSS.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 * @link https://www.npmjs.com/package/css-vars-ponyfill The CSS Variables Ponyfill for old browsers.
 */

// The ponyfill for old browsers.
import cssVars from 'css-vars-ponyfill';

// Helper functions.
import { debounce } from './functions.js';

/* global viewport  */

$(() => {
  /**
   * Set variables:
   * --header-height;
   * --navbar-brand-height;
   * --body-client-width;
   */

  $(window).on(
    'load resize',
    debounce(() => {
      // Header height.
      let headerHeight = $('#site-header').outerHeight();

      if (headerHeight) {
        cssVars({
          variables: { '--header-height': headerHeight + 'px' },
        });
      }
    }, 150)
  );
  /**
   * Set the active breakpoint var.
   * --breakpoint-active;
   *
   * @see js/inc/responsive-toolkit.js Where is defined the window.viewport.
   */
  if (
    viewport &&
    typeof viewport.changed === 'function' &&
    typeof viewport.current === 'function'
  ) {
    viewport.changed(() => {
      cssVars({
        variables: { '--breakpoint-active': viewport.current() },
      });
    }, 150);
  }
});
