/**
 * Helper functions.
 */

/**
 * Check if a value is number.
 *
 * Usage:
 * console.log(isNumeric(12345678912345678912)); // true
 * console.log(isNumeric('2 '));                 // true
 * console.log(isNumeric('-32.2 '));             // true
 * console.log(isNumeric(-32.2));                // true
 * console.log(isNumeric(undefined));            // false
 * console.log(isNumeric(''));                   // false
 * console.log(isNumeric(null));                 // false
 * console.log(isNumeric([]));                   // false
 */
export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Rounds number with step.
 *
 * Examples:
 * roundFloat(2.49, 0.5, 'ceil') ---> 2.5
 * roundFloat(2.49, 0.5, 'round') ---> 2.5
 * roundFloat(2.49, 0.5, 'flour') ---> 2
 *
 * @param {number} value The rounded number.
 * @param step {number} The step of rounding.
 * @param type {string} The type of rounding: round, ceil or floor.
 *
 * @returns {number} Rounded result.
 */
export function roundNumber(value, step = 1.0, type = 'round') {
  step || (step = 1.0);
  const inv = 1.0 / step;
  const mathFunc =
    'ceil' === type ? Math.ceil : 'floor' === type ? Math.floor : Math.round;

  return mathFunc(value * inv) / inv;
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * The function will be called after it stops being called for N milliseconds.
 * If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
 *
 * @link https://davidwalsh.name/javascript-debounce-function
 *
 * Usage: let myEfficientFn = debounce(function() { ... }, 250);
 */
export function debounce(func, wait, immediate) {
  // The returned function will be able to reference this due to closure.
  // Each call to the returned function will share this common timer.
  let timeout;

  // Calling debounce returns a new anonymous function.
  return function () {
    // Reference the context and args for the setTimeout function.
    let context = this,
      args = arguments;

    let later = function () {
      // Inside the timeout function, clear the timeout which will let the next execution run when in 'immediate' mode.
      timeout = null;

      // Check if the function already ran with the immediate flag.
      if (!immediate) {
        // Call the original function with apply.
        // The 'apply' lets you define the 'this' object as well as the arguments (both captured before setTimeout).
        func.apply(context, args);
      }
    };

    // Should the function be called now? If immediate is true and not already in a timeout then the answer is: Yes.
    let callNow = immediate && !timeout;

    // This is the basic debounce behaviour where you can call this function several times,
    // but it will only execute once [before or after imposing a delay].
    // Each time the returned function is called, the timer starts over.
    clearTimeout(timeout);

    // Set the new timeout.
    timeout = setTimeout(later, wait);

    // Immediate mode and no wait timer? Execute the function.
    if (callNow) func.apply(context, args);
  };
}
