// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; import './autoload/css-variables.js'; import './autoload/functions.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/responsive-toolkit';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  //ADD CATEGORY TO COOKIE
  let homeBtn = $('.s-jobs__cat-item'),
    cookieCat;

  homeBtn.on('click', function () {
    cookieCat = $(this).find('.arrow-right').attr('data-term');
    // console.log(cookieCat);
    document.cookie = 'cat =  ' + cookieCat + ' ; path=/ ;';
    // console.log(document.cookie);
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  let $rootDropdowns = $('#site-header .navbar-nav.collapse');
  let $navbarToggler = $('button.navbar-toggler');
  $rootDropdowns.on('show.bs.collapse', () => {
    $('body').addClass('is-opened-dropdown');
  });
  $rootDropdowns.on('hide.bs.collapse', () => {
    $('body').removeClass('is-opened-dropdown');
  });

  $(window).on('load resize', () => {
    if ('true' === $navbarToggler.attr('aria-expanded')) {
      $navbarToggler.trigger('click');
    }
  });
  $('.js-steps').slick({
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    infinite: false,
    prevArrow: $('button.js-nav-prev'),
    nextArrow: $('button.js-nav-next'),
    rows: 0,
    responsive: [
      {
        breakpoint: 1640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centreMode: false,
        },
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centreMode: true,
        },
      },
    ],
  });
  /* Slick needs no get Reinitialized on window Resize after it was destroyed */
  $(window).on('load resize orientationchange', function () {
    let $carousel = $('.js-showrooms');
    if ($(window).width() > 767) {
      if ($carousel.hasClass('slick-initialized')) {
        $carousel.slick('unslick');
      }
    } else if ($(window).width() <= 767) {
      if (!$carousel.hasClass('slick-initialized')) {
        $carousel.slick({
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          infinite: false,
          centerMode: true,
          rows: 0,
        });
      }
    }
  });
  /**
   * Scroll to anchor
   */
  $('a[href^=#]').click(function (e) {
    e.preventDefault();
    let dest = $(this).attr('href');
    let headerHeight = $('.header').outerHeight();
    if (dest != '#') {
      $('html,body').animate(
        { scrollTop: $(dest).offset().top - headerHeight },
        700
      );
    }
  });
  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Toggle hide text
   */
  let defaultHeight = 0;
  let text = $('p.js-hidden-text');
  let button = $('a.js-toggle-btn');

  if (text.length && button.length) {
    let textHeight = text[0].scrollHeight;
    let textMargin = text.css('margin-bottom');
    let buttonTextMore = button.text();
    let buttonTextLess = button.data('less');
    text.css({
      'max-height': defaultHeight + 'em',
      overflow: 'hidden',
      'margin-bottom': defaultHeight,
    });
    button.on('click', function () {
      let newHeight = 0;
      let newMargin = 0;
      if (text.hasClass('active')) {
        newHeight = defaultHeight + 'em';
        newMargin = defaultHeight;
        text.removeClass('active');
        $(this).text(buttonTextMore);
      } else {
        newHeight = textHeight;
        newMargin = textMargin;
        text.addClass('active');
        $(this).text(buttonTextLess);
      }
      text.animate(
        {
          'max-height': newHeight,
          'margin-bottom': newMargin,
        },
        500
      );
    });
  }

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        // TODO: replace with bootstrap toggle
        // $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  $('html')
    .toggleClass('scrolling-top', st < lastScrollTop)
    .toggleClass('scrolling-bottom', st > lastScrollTop);
  if (st === 0) {
    $('html').removeClass('scrolling-top scrolling-bottom');
  }
  lastScrollTop = st;
});
/**
 * Ajax filter and paginaton
 */
$(() => {
  function getPosts(page, cat, button, search, filter) {
    let container = $('#entry-jobs'),
      head = container.find('.entry-jobs__header'),
      body = container.find('.entry-jobs__body'),
      jobs_per_page = body.data('jobs'),
      loader =
        '<div class="lds-spinner__wrapper"><div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>',
      footer = container.find('.entry-jobs__footer'),
      currentCat = null,
      currentFilter = container.find('.js-filter').val();
    if (head.find('.js-filter-cat.is-active').length) {
      currentCat = head.find('.js-filter-cat.is-active').data('cat');
    }
    page =
      page && typeof page !== 'undefined' && page !== '' && page !== null
        ? page
        : 1;
    cat =
      cat && typeof cat !== 'undefined' && cat !== '' && cat !== null
        ? cat
        : currentCat
        ? currentCat
        : 'all';
    button =
      button &&
      typeof button === 'object' &&
      button !== null &&
      button.length &&
      true
        ? button
        : null;
    search =
      search && typeof search !== 'undefined' && search !== null
        ? search
        : $('.js-search .js-input').val();
    filter =
      filter &&
      typeof filter !== 'undefined' &&
      filter !== '' &&
      filter !== null
        ? filter
        : currentFilter
        ? currentFilter
        : 'all';
    let data = {
      action: 'loadmore',
      page: page,
      cat: cat,
      s: search,
      filter: filter,
      posts_per_page: jobs_per_page,
    };
    $.ajax({
      url: window.ajax_object.ajax_url,
      type: 'POST',
      data: data,
      beforeSend: () => {
        if (button) {
          button.find('button').addClass('loading').attr('disabled', true);
        }
        body.append(loader);
      },
      success: (response) => {
        body.html(response.loop);
        if (button) {
          if (button.hasClass('posts-nav'))
            $('html,body').animate({ scrollTop: 0 }, 500);
          button.find('button').removeClass('loading').attr('disabled', false);
          button.addClass('is-active');
        }
        footer.html(response.pagin);
        container.find('.entry-jobs__btn-reset').html(response.reset);
        container.find('.entry-jobs__count').html(response.count);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  function removeActive(buttons) {
    if (typeof buttons === 'object' && buttons.length) {
      buttons.each(function (i, el) {
        $(el).removeClass('is-active');
      });
    }
  }

  $(document).ready(function () {
    //GET COOkIE FUNCTION
    function getCookie(name) {
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(';');

      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split('=');

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name == cookiePair[0].trim()) {
          // Decode the cookie value and return
          return decodeURIComponent(cookiePair[1]);
        }
      }

      // Return null if not found
      return null;
    }

    let cat = getCookie('cat'),
      button = $('.js-filter-cat'),
      allBtn = $('.filter-item--all'),
      activeBtn;

    if (cat !== '') {
      allBtn.removeClass('is-active');
    }

    button.each(function () {
      if ($(this).attr('data-cat') == cat) {
        activeBtn = $(this);
      }
    });

    getPosts(null, cat, activeBtn);

    document.cookie = 'cat =  ' + '' + ' ; path=/ ;';

    $(document).on('click', '.js-filter-cat', function () {
      let filter = $('.js-filter-cat'),
        button = $(this),
        cat = button.data('cat');
      removeActive(filter);
      getPosts(null, cat, button);
    });
    $(document).on('submit', '.js-search', function (e) {
      e.preventDefault();
      let search = $(this).find('.js-input').val();
      getPosts(null, false, false, search);
    });
    $(document).on('change', '.js-filter', function () {
      let filter = $(this).val();
      getPosts(false, false, false, false, filter);
    });
    $(document).on(
      'click',
      '.ajax-pagination .posts-nav:not(.disabled-link)',
      function (e) {
        e.preventDefault();
        let button = $(this),
          page = button.data('page');
        getPosts(page, null, button);
      }
    );
    $(document).on('click', '.js-reset', function () {
      $(this).attr('disabled', true);
      let filter = $('.js-filter-cat');
      removeActive(filter);
      $('.js-filter').val('all');
      $('div.styledSelect').text($('select.js-filter option:first').text());
      $('.js-search .js-input').val('');
      filter.each(function () {
        if ($(this).hasClass('filter-item--all')) {
          $(this).addClass('is-active');
          filter.parent().siblings('.init').html($(this).find('button').html());
        }
      });
      getPosts(null, 'all', null, '', 'all');
    });
  });
});
/**
 * Custom  select from ul list
 */
$(document).ready(function () {
  let filterWrapper = $('.js-filter-cats'),
    catFilter = filterWrapper.find('.filter-cats'),
    allOptions = catFilter.children('li');
  $(window).on('load resize orientationchange', function () {
    if ($(window).width() > 767) {
      if (catFilter.length) {
        if (!filterWrapper.hasClass('is-open')) {
          allOptions.show();
          filterWrapper.addClass('is-open');
        }
      }
    } else if ($(window).width() <= 767) {
      if (catFilter.length) {
        if (filterWrapper.hasClass('is-open')) {
          allOptions.hide();
          filterWrapper.removeClass('is-open');
        }
      }
    }
  });
  filterWrapper.on('click', '.init', function () {
    if ($(window).width() <= 767) {
      filterWrapper.toggleClass('is-open');
      $(this).next('ul').children('li').toggle();
    }
  });

  catFilter.on('click', 'li', function () {
    if ($(window).width() <= 767) {
      filterWrapper.removeClass('is-open');
      catFilter.parent().children('.init').html($(this).find('button').html());
      allOptions.toggle();
    }
  });
  $(document).click(function (e) {
    if ($(window).width() <= 767) {
      let $tgt = $(e.target);
      if (!$tgt.closest('.js-filter-cats').length) {
        allOptions.hide();
        filterWrapper.removeClass('is-open');
      }
    }
  });
  /**
   * Custom  style for select
   */
  let select = $('#jobsLocation'),
    numberOfOptions = select.children('option').length;
  select.addClass('s-hidden');
  select.wrap('<div class="select"></div>');
  select.after('<div class="styledSelect"></div>');
  let $styledSelect = select.next('div.styledSelect');
  $styledSelect.text(select.children('option').eq(0).text());
  let $list = $('<ul />', {
    class: 'options',
  }).insertAfter($styledSelect);
  for (let i = 0; i < numberOfOptions; i++) {
    $('<li />', {
      text: select.children('option').eq(i).text(),
      rel: select.children('option').eq(i).val(),
    }).appendTo($list);
  }
  let $listItems = $list.children('li');
  $($listItems.get(0)).addClass('is-active');
  $styledSelect.click(function (e) {
    e.stopPropagation();
    $(this).toggleClass('active').next('ul.options').toggle();
  });
  $listItems.mouseenter(function (e) {
    e.stopPropagation();
    $listItems.removeClass('is-active');
  });
  $listItems.click(function (e) {
    e.stopPropagation();
    $styledSelect.text($(this).text()).removeClass('active');
    select.val($(this).attr('rel')).trigger('change');
    $list.hide();
    $(this).addClass('is-active');
  });
  $(document).click(function () {
    $styledSelect.removeClass('active');
    $list.hide();
  });
});
